import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';
import '../style.css';

function MainPage() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="content-image-container">
        <video
          controls
          className="content-item video-player"
          controlsList="nodownload"
          disablePictureInPicture
          poster={`${process.env.PUBLIC_URL}/images/2021/cover.jpeg`}
        >
          <source src={`https://okhumane.sfo2.cdn.digitaloceanspaces.com/2021-yule-log.mp4`} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div className="content-image-container">
        <a href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=okhumane&id=126">
          <img className="content-item" alt="donate" src={`${process.env.PUBLIC_URL}/images/2021/donate.png`} />
        </a>
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="nice list" src={`${process.env.PUBLIC_URL}/images/2021/nice-list.png`} />
      </div>

      <div className="content-image-container">
        <a href="https://www.youtube.com/watch?v=C9GUCKzZu1A" target="_blank" rel="noopener noreferrer" >
            <img className="content-item" alt="Stream on YouTube" src={`${process.env.PUBLIC_URL}/images/2021/youtube.png`} />
        </a>
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="lunchbox" src={`${process.env.PUBLIC_URL}/images/2021/doggo.jpg`} />
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="track list" src={`${process.env.PUBLIC_URL}/images/2021/track-list.png`} />
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="credits" src={`${process.env.PUBLIC_URL}/images/2021/credits.png`} />
      </div>

      <div className="content-image-container">
        <div className="content-item" style={{display: "inline-block", marginTop: '40px'}}>
            <img style={{float: 'left'}} width="48%" alt="yule dog" src={`${process.env.PUBLIC_URL}/images/2021/kitteh.jpg`} />
            <img style={{float: 'right'}} width="48%" alt="yule cat" src={`${process.env.PUBLIC_URL}/images/2021/pupper.jpg`} />
        </div>
      </div>

      <div className="content-image-container">
        <img className="content-item about-item" alt="about" src={`${process.env.PUBLIC_URL}/images/2021/about.png`} />
      </div>

      <div className="content-image-container" style={{marginBottom: '30px'}}>
        <a href="/2020">2020 Yule Log</a>
      </div>
    </ThemeProvider>
  );
}

export default MainPage;
