import React, { useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import './style.css';
import BigVideo from '../../components/BigVideo';
import PageSection from '../../components/PageSection';
import InfoSection from '../../components/InfoSection';

function Promo2019() {
  const infoSectionRef = useRef(null);

  const handleScrollClick = () => {
    if (infoSectionRef && infoSectionRef.current) {
      infoSectionRef.current.scrollIntoView({behavior: 'smooth'})
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageSection color="#f6f6f6" noPadding>
        <BigVideo onScrollClick={handleScrollClick}></BigVideo>
      </PageSection>
      <InfoSection ref={infoSectionRef} />
    </ThemeProvider>
  );
}

export default Promo2019;