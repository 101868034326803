import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { makeTheme } from '../theme';
import '../style.css';

const theme = makeTheme('#c1a8a2');

function MainPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="content-image-container">
        <video
          controls
          className="content-item video-player"
          controlsList="nodownload"
          disablePictureInPicture
          poster={`${process.env.PUBLIC_URL}/images/2022/cover.jpeg`}
        >
          <source src={`https://okhumane.sfo2.cdn.digitaloceanspaces.com/2022-yule-log.mp4`} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div className="content-image-container">
        <a href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=okhumane&id=126">
          <img className="content-item" alt="donate" src={`${process.env.PUBLIC_URL}/images/2022/donate.webp`} />
        </a>
      </div>

      <div className="content-image-container">
        <a href="https://youtu.be/HBLE4NKygZQ" target="_blank" rel="noopener noreferrer" >
            <img className="content-item" alt="Stream on YouTube" src={`${process.env.PUBLIC_URL}/images/2022/youtube.webp`} />
        </a>
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="track list" src={`${process.env.PUBLIC_URL}/images/2022/track-list.webp`} />
      </div>

      <div className="content-image-container">
        <div className="content-item" style={{display: "inline-block"}}>
            <img style={{float: 'left'}} width="48%" alt="yule dog" src={`${process.env.PUBLIC_URL}/images/2022/nyan.jpeg`} />
            <img style={{float: 'right'}} width="48%" alt="yule cat" src={`${process.env.PUBLIC_URL}/images/2022/pup.jpeg`} />
        </div>
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="nice list" src={`${process.env.PUBLIC_URL}/images/2022/nice-list.webp`} />
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="credits" src={`${process.env.PUBLIC_URL}/images/2022/credits.webp`} />
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="about" src={`${process.env.PUBLIC_URL}/images/2022/about.webp`} />
      </div>

      <div className="content-image-container" style={{marginBottom: '30px'}}>
        <a href="/2021">2021 Yule Log</a>
      </div>
    </ThemeProvider>
  );
}

export default MainPage;
