import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import withWidth from '@material-ui/core/withWidth';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const videoStyle = {
  width: '100%',
  height: '100vh',
  objectFit: 'cover',
};

const containerStyle = {
  position: 'relative',
};

const downArrowStyle = {
  position: 'absolute',
  bottom: '0',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  fontSize: '6em',
  color: '#222222',
};

function BigVideo({children, onScrollClick, width}) {
  const theme = useTheme();
  const isSmallDisplay = useMediaQuery(theme.breakpoints.down('sm'));

  const posterUrl = isSmallDisplay ? `${process.env.PUBLIC_URL}/mobile_poster.jpg` : `${process.env.PUBLIC_URL}/header.jpg`;
  const player = (
    <video autoPlay loop muted style={videoStyle} poster={posterUrl}>
      <source src={`${process.env.PUBLIC_URL}/orig.mp4`} />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );

  const downArrow = (
    <div style={downArrowStyle}>
      <div style={{cursor: 'pointer', display: 'flex'}} onClick={onScrollClick}>
        <KeyboardArrowDownIcon fontSize="inherit" />
      </div>
    </div>
  );

  return (
    <div style={containerStyle}>
      {player}
      {children}
      {downArrow}
    </div>
  );
}

BigVideo.propTypes = {
  children: PropTypes.node,
  onScrollClick: PropTypes.func,
  width: PropTypes.number.isRequired,
};

BigVideo.defaultProps = {
    onScrollClick: () => {},
};

export default withWidth()(BigVideo);
