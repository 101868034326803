import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f6f6f6',
    },
    text: {
      primary: '#000',
      secondary: '#777',
      white: '#fff'
    }
  },
  typography: {
    fontSize: 14,
  }
});

export default theme;
