import React from 'react';
import { Grid } from '@material-ui/core';

import PageSection from './PageSection';
import EmailForm from './EmailForm';

const headerStyle = {
  color: '#555',
  fontSize: '38px',
  fontWeight: '800',
  fontFamily: "'Oswald', sans-serif",
}

const textStyle = {
  color: '#777',
  fontSize: '16px',
  fontWeight: '300',
  fontFamily: "'Montserrat', sans-serif",
}

const InfoSection = React.forwardRef((props, ref) => {
  return (
    <PageSection color="#f6f6f6">
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
            <div style={{margin: '0 10px 80px 10px'}}>
              <div ref={ref} className="text-title">OK HUMANE YULE LOG</div>
              <div style={headerStyle}>LAUNCHES NOVEMBER 29TH</div>
              <div style={textStyle}>
                  <p>Celebrate the season with puppies, kittens and your favorite local musicians. This visual album features rescue dogs and cats frolicking in front of a festive fireplace scene, set to holiday tunes by favorite local musicians. The OK Humane Yule Log can be streamed and downloaded with any donation to the Oklahoma Humane Society, beginning November 29th.</p>
                  <p>Check back on November 29th for full access to the OK Humane Yule Log, or put your email below to be notified when it launches!</p>
                  <EmailForm />
            </div>
          </div>
        </Grid>
      </Grid>
    </PageSection>
  );
});

export default InfoSection;
