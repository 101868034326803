import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';
import '../style.css';

function MainPage() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="content-image-container">
        <video
          controls
          className="content-item video-player"
          controlsList="nodownload"
          disablePictureInPicture
          poster={`${process.env.PUBLIC_URL}/images/poster.jpeg`}
        >
          <source src={`${process.env.PUBLIC_URL}/2019-yule-log.mp4`} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div className="content-image-container">
        <a href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=okhumane&id=145">
          <img className="content-item" alt="donate" src={`${process.env.PUBLIC_URL}/images/donate.png`} />
        </a>
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="nice list" src={`${process.env.PUBLIC_URL}/images/nice-list.png`} />
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="yule cats" src={`${process.env.PUBLIC_URL}/images/yule-picture.jpg`} />
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="track list" src={`${process.env.PUBLIC_URL}/images/track-list.png`} />
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="credits" src={`${process.env.PUBLIC_URL}/images/credits.png`} />
      </div>

      <div className="content-image-container">
        <div className="content-item" style={{display: "inline-block", marginTop: '40px'}}>
          <img style={{float: 'left'}} width="48%" alt="yule cat 1" src={`${process.env.PUBLIC_URL}/images/yule-cat-1.jpg`} />
          <img style={{float: 'right'}} width="48%" alt="yule cat 2" src={`${process.env.PUBLIC_URL}/images/yule-cat-2.jpg`} />
        </div>
      </div>

      <div className="content-image-container">
        <img className="content-item about-item" alt="about" src={`${process.env.PUBLIC_URL}/images/about.png`} />
      </div>
    </ThemeProvider>
  );
}

export default MainPage;
