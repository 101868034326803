import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const makeTheme = (backgroundColor = '#edeaea') => createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: backgroundColor,
    },
    text: {
      primary: '#000',
      secondary: '#777',
      white: '#fff'
    }
  },
  typography: {
    fontSize: 14,
  }
});

const theme = makeTheme();

export default theme;
