import React, { useState, useMemo } from 'react';
import { TextField, Button } from '@material-ui/core';

const useSingleInputForm = (init, url) => {
  const initSubmitted = useMemo(() => window.localStorage.getItem('submitted') === 'true', []);
  const [val, setVal] = useState(init);
  const [submitted, setSubmitted] = useState(initSubmitted);

  const handleSubmit = () => {
    if (val === '') {
      return;
    }
    let r = new XMLHttpRequest();
    r.open('POST', url);
    r.setRequestHeader('Content-Type', 'application/json');
    r.send(JSON.stringify({email: val}));
    window.localStorage.setItem('submitted', 'true');
    setSubmitted(true);
  }

  return [val, setVal, handleSubmit, submitted];
};

function EmailForm() {
  const [email, setEmail, handleSubmit, submitted] = useSingleInputForm('', '/api/reminders');

  if (submitted) {
    return (
      <div style={{height: '48px', lineHeight: '48px', fontWeight: 'bold', textAlign: 'center'}}>
          {"We'll notify you when the Yule Log is available!"}
      </div>
    );
  }

  const handleFormSubmit = e => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div style={{marginTop: '30px', display: 'flex', alignContent: 'flex-end'}}>
        <div style={{flexGrow: '1'}}>
          <TextField type="email" required value={email} onChange={e => setEmail(e.target.value)} label="Email" fullWidth />
        </div>
        <div style={{display: 'flex', alignContent: 'flex-end', paddingLeft: '10px'}}>
          <Button type="submit" variant="outlined">Notify Me</Button>
        </div>
      </div>
    </form>
  );
}

export default EmailForm;