import React from 'react';
import PropTypes from 'prop-types';

function PageSection({children, color, noPadding}) {
  const style = {
    backgroundColor: color,
    marginTop: noPadding ? '0' : '70px',
  };

  return (
    <div style={style}>{children}</div>
  );
}

PageSection.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  noPadding: PropTypes.bool,
};

PageSection.defaultProps = {
  noPadding: false,
  color: '#ffffff',
  children: null,
};

export default PageSection;
