import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';
import '../style.css';

function MainPage() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="content-image-container">
        <video
          controls
          className="content-item video-player"
          controlsList="nodownload"
          disablePictureInPicture
          poster={`${process.env.PUBLIC_URL}/images/2020/poster.jpg`}
        >
          <source src={`https://okhumane.sfo2.cdn.digitaloceanspaces.com/2020-yule-log.mp4`} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div className="content-image-container">
        <a href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=okhumane&id=126">
          <img className="content-item" alt="donate" src={`${process.env.PUBLIC_URL}/images/donate.png`} />
        </a>
      </div>

      <div className="content-image-container">
        <img className="content-item" alt="nice list" src={`${process.env.PUBLIC_URL}/images/nice-list.png`} />
      </div>

      <div className="content-image-container">
        <div className="content-item" style={{display: "inline-block", marginBottom: '50px'}}>
            <a href="https://oklahomahumanesociety.bandcamp.com/album/ok-humanes-2020-yule-log-soundtrack" target="_blank" rel="noopener noreferrer">
                <img style={{float: 'left'}} width="50%" alt="Purchase Album" src={`${process.env.PUBLIC_URL}/images/2020/bandcamp.png`}/>
            </a>
            <a href="https://www.youtube.com/watch?v=JDi2TL1DxVI" target="_blank" rel="noopener noreferrer" >
                <img style={{float: 'right'}} width="50%" alt="Stream on YouTube" src={`${process.env.PUBLIC_URL}/images/2020/youtube.png`} />
            </a>
        </div>
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="lunchbox" src={`${process.env.PUBLIC_URL}/images/2020/chonker.jpg`} />
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="track list" src={`${process.env.PUBLIC_URL}/images/2020/track-list.png`} />
      </div>

      <div className="content-image-container">
          <img className="content-item" alt="credits" src={`${process.env.PUBLIC_URL}/images/2020/credits.png`} />
      </div>

      <div className="content-image-container">
        <div className="content-item" style={{display: "inline-block", marginTop: '40px'}}>
            <img style={{float: 'left'}} width="48%" alt="yule dog" src={`${process.env.PUBLIC_URL}/images/2020/doggo.jpg`} />
            <img style={{float: 'right'}} width="48%" alt="yule cat" src={`${process.env.PUBLIC_URL}/images/2020/kitty.jpg`} />
        </div>
      </div>

      <div className="content-image-container">
        <img className="content-item about-item" alt="about" src={`${process.env.PUBLIC_URL}/images/about.png`} />
      </div>

      <div className="content-image-container" style={{marginBottom: '30px'}}>
        <a href="/2019">2019 Yule Log</a>
      </div>
    </ThemeProvider>
  );
}

export default MainPage;
